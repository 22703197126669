import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";

function IndexPage() {
  const settings = {
    autoplay: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  return (
    <Layout>
    
      <SEO
        title="Marina Trapp Logopädie"
        keywords={[`Marina`, `Trapp`, `Logopädie`, `Sprachtherapie`, `Therapie`, `Sprachstörung`, `Schlaganfall`, `Speech therapy`]}
      />
          <section className="my-10">
            <h1 className="w-full my-2 text-5xl leading-tight text-center text-main hidden sm:block">Marina Trapp, BA, BSc</h1>
            <p className="text-gray-600 mb-6 w-full text-center text-xl">Mobile Logopädie für Erwachsene im Raum Innsbruck und Umgebung</p>
          </section>

          <div className="px-7 limited-width mx-auto">
            <Slider {...settings}>
              <div className="container">
                <StaticImage src="../images/slideshow/4N6A4150.jpg" alt="Slide10" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4037.jpg" alt="Slide4" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4066.jpg" alt="Slide6" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4008.jpg" alt="Slide1" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4127.jpg" alt="Slide8" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4046.jpg" alt="Slide5" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4082.jpg" alt="Slide7" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4019.jpg" alt="Slide2" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4160.jpg" alt="Slide11" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4191.jpg" alt="Slide14" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4199.jpg" alt="Slide15" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4144.jpg" alt="Slide9" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4031.jpg" alt="Slide3" className="rounded limited-height limited-width"/>
              </div>
              <div>
                <StaticImage src="../images/slideshow/4N6A4185.jpg" alt="Slide13" className="rounded limited-height limited-width"/>
              </div>
            </Slider>
          </div>
          {/* Alternate features */}
          <section className="bg-white border-b py-8">
            <div className="container max-w-5xl mx-auto m-8">
              <h2 className="w-full my-2 text-5xl leading-tight text-center text-gray-800" id="start">Herzlich Willkommen auf meiner Website!</h2>
              <div className="w-full mb-4">
                <div className="h-1 gradient w-100 mx-8 my-0 py-0 rounded-t"></div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-5/6 sm:w-1/2 p-6">
                  <p className="text-gray-600 mb-8">Jeder und jede von uns ist im Alltag auf Kommunikation angewiesen.
                    <span className="font-bold italic"> Sprache und Sprechen</span> verbindet uns mit unserer Umwelt, wir können uns ausdrücken und am sozialen Leben teilnehmen. Sind <span className="font-bold italic">Nahrungsaufnahme und Schlucken</span> nicht mehr möglich oder erschwert, so kann das geplante Familienessen zum Stressfaktor werden, der Nachmittagskaffee mit Freunden kann unter Umständen beschwerlich sein, der Genussfaktor tritt in den Hintergrund. Essen und Trinken sind nicht nur lebensnotwendig, sondern verbinden uns Menschen miteinander.
                  </p>
                  <p className="text-gray-600 mb-8">
                    Doch was tun, wenn eine oder mehrere dieser Funktionen beeinträchtigt und eigene Strategien zur Bewältigung nicht mehr ausreichend sind? In der logopädischen Therapie finden Sie Unterstützung – gemeinsam wird daran gearbeitet, diese Funktionen wieder schrittweise zu normalisieren.
                  </p>
                  <p className="text-gray-600 mb-8">
                    Ich helfe Ihnen gerne dabei!
                  </p>
                </div>
                <div className="w-full sm:w-1/2 p-6 m-auto">
                  <StaticImage src="../images/text/4N6A4175.jpg" alt="Text photo 1" className="rounded" />
                </div>
              </div>


              <div className="flex flex-wrap flex-col-reverse sm:flex-row">
                <div className="w-full sm:w-1/2 p-6 mt-6">
                  <StaticImage src="../images/text/4N6A4113.jpg" alt="Text photo 1" className="rounded" />
                </div>
                <div className="w-full sm:w-1/2 p-6 mt-6">
                  <div className="align-middle">
                    <h3 className="text-3xl text-gray-800 leading-none mb-3">Was ist Logopädie?</h3>
                    <p className="text-gray-600 mb-8">
                      Die Logopädie beschäftigt sich mit Störungen in den Bereichen <span className="font-bold italic">Sprache, Sprechen, Stimme, Atmung</span> und <span className="font-bold italic">Schlucken</span>. Sind Einschränkungen in einem oder in mehreren der aufgezählten Bereiche gegeben, so kann dies große Auswirkungen auf die Lebensqualität des bzw. der Betroffenen haben.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Therapy */}
          <section className="bg-white border-b py-8">

            <div className="container mx-auto flex flex-wrap pt-4 pb-12">

              <h3 className="w-full my-2 text-3xl leading-tight text-center text-gray-800" id="therapie">Therapie</h3>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 my-0 py-0 rounded-t"></div>
              </div>

              <p className="text-gray-600 mx-6">Ich biete logopädische Therapie für Erwachsene nach Schlaganfall, Schädel- Hirn- Trauma, Tumor, bzw. neurodegenerativen Erkrankungen, wie ALS oder M. Parkinson an. Dabei behandle ich folgende Störungsbilder: </p>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg card">
                  <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4">Aphasie</div>
                  <p className="text-gray-800 text-base px-6 mb-5">
                    Verlust des Sprechvermögens oder Sprachverstehens infolge einer Erkrankung des Sprachzentrums im Gehirn
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg card">
                  <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4">Dysarthrie</div>
                  <p className="text-gray-800 text-base px-6 mb-5">
                    Organisch bedingte Sprachstörung, speziell Störung der Lautbildung infolge mangelhafter Koordination der Sprechwerkzeuge, die besonders bei Gehirnverletzungen oder -erkrankungen auftritt
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg card">
                  <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4">Dysphagie</div>
                  <p className="text-gray-800 text-base px-6 mb-5">
                    Durch verschiedene Ursachen hervorgerufene Schluckstörung (mit oder ohne Schmerzen)
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg card">
                  <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4">Dysphonie</div>
                  <p className="text-gray-800 text-base px-6 mb-5">
                    Beeinträchtigung des stimmlichen Teils der Artikulation bei Erkrankungen oder Funktionsstörungen des Kehlkopfes und des Ansatzrohres
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/3 p-6 flex-col flex-grow flex-shrink hidden md:flex">
                <StaticImage className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg card"src="../images/slideshow/4N6A4191.jpg"
                 alt="Füllbild">
                </StaticImage>
              </div>


              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg card">
                  <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4">Fazialisparese</div>
                  <p className="text-gray-800 text-base px-6 mb-5">
                    Gesichtslähmung
                  </p>
                </div>
              </div>

              <p className="text-gray-600 mx-6">Die Behandlung erfolgt als Einzeltherapie und wird individuell auf die jeweiligen Bedürfnisse meiner Patient*innen angepasst. Gemeinsam werden zu Beginn Therapieziele festgelegt und am Ende des Behandlungsblockes reflektiert.</p>

              <div className="mt-4">
                <h3 className="text-2xl text-gray-800 leading-none mt-8 mb-4 mx-6">Wie kommen Sie zu mir?</h3>
                <p className="text-gray-600 mx-6">Um meine logopädischen Leistungen in Anspruch nehmen zu können, benötigen Sie vor Therapiebeginn eine <span className="font-bold italic">ärztliche Verordnung</span> für Logopädie (ausgestellt von Ihrem Hausarzt, Neurologe, HNO- Arzt). Nach anschließender <span className="font-bold italic">Bewilligung</span> durch Ihren Sozialversicherungsträger (ÖGK, BVAEB, SVS, …) vereinbaren wir unsere erste gemeinsame Logopädieeinheit.</p>
              </div>
            </div>
          </section>

          {/* About me */}
          <section className="bg-white border-b py-8">
            <div className="container max-w-5xl mx-auto m-8">
              <h3 className="w-full my-2 text-3xl leading-tight text-center text-gray-800" id="about-me">Über mich</h3>
              <div className="w-full mb-4">
                <div className="h-1 gradient w-52 mx-auto my-0 py-0 rounded-t"></div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-5/6 sm:w-1/2 p-6 self-center">
                  <p className="text-gray-600 mb-8">
                    Mein Name ist Marina Trapp, ich bin 1988 in Innsbruck geboren und wohne im schönen Absam.
                  </p>
                  <p className="text-gray-600 mb-8">Im Jahr 2010 habe ich das Studium der Logopädie an der Fachhochschule Gesundheit (FhG Tirol) mit gutem Erfolg abgeschlossen und habe meine Leidenschaft für den Beruf entdeckt.</p>
                  <p className="text-gray-600 mb-8">Nach mehrjähriger Tätigkeit im stationären Bereich (Salzburger Landesklinik, Landeskrankenhaus Hochzirl) übe ich meinen Beruf seit mehreren Jahren selbstständig und ausschließlich mobil, im Rahmen von Hausbesuchen, aus.</p>
                  <p className="text-gray-600 mb-8">Seit September 2020 bin ich stolze Mama eines Sohnes.</p>
                </div>
            
                <div className="w-full sm:w-1/2 p-6 m-auto">
                  <Slider {...settings}>
                    <div>
                      <StaticImage src="../images/marina/4N6A4260.jpg" alt="Marina-0" className="rounded" />
                    </div>
                    {/* <div>
                      <StaticImage src="../images/marina/4N6A4299.jpg" alt="Marina-0" className="rounded"/>
                    </div> */}
                    <div>
                      <StaticImage src="../images/marina/4N6A4361.jpg" alt="Marina-0" className="rounded" />
                    </div>
                    {/* <div>
                      <StaticImage src="../images/marina/4N6A4438.jpg" alt="Marina-0" className="rounded"/>
                    </div> */}
                    <div>
                      <StaticImage src="../images/marina/4N6A4519.jpg" alt="Marina-0" className="rounded" />
                    </div>
                    <div>
                      <StaticImage src="../images/marina/4N6A4552.jpg" alt="Marina-0" className="rounded" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>


          {/* Waves SVG */}
          <svg className="wave-top gradient" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
                <g className="wave" fill="#f8fafc">
                  <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                  <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                    <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                    <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>

          {/* CTA block */}
          <section className="text-center py-6 mb-4 w-100 gradient">

            <h1 className="w-full my-2 text-5xl leading-tight text-center text-white" id="kontakt">Kontakt</h1>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
            </div>

            <h3 className="my-4 text-3xl leading-tight text-white">Logopädie Marina Trapp, BA, BSc</h3>
            <h3 className="text-2xl leading-tight">+43 650 517 4923</h3>
            <h3 className="text-2xl leading-tight">anfrage@logopaedie-trapp.at</h3>
          </section>

          {/* Footer */}
          <footer className="bg-white pb-4">
            <div className="mx-auto">
              <div className="w-full flex flex-col md:flex-row md:gap-4">
                  <a href="/impressum" className="m-auto md:text-sm text-gray-500">Impressum</a>
              </div>
              <div className="w-full flex flex-col md:flex-row">
              <p className="text-gray-500 mx-auto">
                © 2021 Marina Trapp, BA, BSc
              </p>
              </div>
            </div>
          </footer>
    </Layout>
  );
}

export default IndexPage;
